import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { formValueSelector } from "redux-form";
import { datadogRum } from '@datadog/browser-rum';
import "Error.scss";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    const renderingError = new Error(error.message);
    renderingError.name = `ReactRenderingError`;
    renderingError.stack = errorInfo.componentStack;
    renderingError.cause = error;
 
    datadogRum.addError(renderingError);

  }

  render() {
    if (this.state.error) {
      const { email, first, last } = this.props;

      //render fallback UI
      return (
        <div className="error-boundary">
          <div className="main">
            <div>
              <div>
                <span>Oops, </span>
                <span>we hit a snag.</span>
                <span>Sorry about that!</span>
              </div>
              <svg viewBox="0 0 200 600">
                <polygon points="118.302698 8 59.5369448 66.7657528 186.487016 193.715824 14 366.202839 153.491505 505.694344 68.1413353 591.044514 200 591.044514 200 8" />
              </svg>
            </div>
            <svg className="crack" viewBox="0 0 200 600">
              <polyline points="118.302698 8 59.5369448 66.7657528 186.487016 193.715824 14 366.202839 153.491505 505.694344 68.1413353 591.044514" />
            </svg>
            <div>
              <svg viewBox="0 0 200 600">
                <polygon points="118.302698 8 59.5369448 66.7657528 186.487016 193.715824 14 366.202839 153.491505 505.694344 68.1413353 591.044514 0 591.044514 0 8" />
              </svg>
          
            </div>
          </div>

          <div className="eb-back-link-wrap">
            You can also{" "}
            <span onClick={this.props.history.goBack} className="faux-link">
              Go Back
            </span>{" "}
            and try again.
          </div>

        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

const selector = formValueSelector("application");

const mapStateToProps = state => {
  return {
    business_id: selector(state, "business_id"),
    contact_id: selector(state, "owner_1_id"),
    mobile: selector(state, "owner_1_mobile"),
    email: selector(state, "owner_1_email"),
    first: selector(state, "owner_1_first"),
    last: selector(state, "owner_1_last")
  };
};

ErrorBoundary = connect(mapStateToProps)(ErrorBoundary);

export default withRouter(ErrorBoundary);

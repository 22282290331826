import { useEffect } from "react";
import { handleSignOut } from "./utils/reduxUtils";
import { connect } from "react-redux";

function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

function isTokenExpired(token) {
    const payload = parseJwt(token);
    const now = Math.floor(Date.now() / 1000); // Convert to UNIX epoch time in seconds
    return now > payload.exp;
}


const checkTokenExpiry = (cb) => () => {
    const persistDataStr = localStorage.getItem('persist:app');
    const persistData = JSON.parse(persistDataStr);
    const token = persistData?.user ? JSON.parse(persistData?.user)?.token : null;
    // console.log('checkTokenExpiry: token = ', token, ' ; from data = ', persistData)
    if (token && isTokenExpired(token)) {
        cb()
    }
}

export const CHECK_EXPIRY_EVERY_X_SECONDS = 30;

export const useCheckTokenExpiry = (cb) => {
    useEffect(() => {
        checkTokenExpiry(cb);
        const checkExpiryIntervalId = setInterval(checkTokenExpiry(cb), CHECK_EXPIRY_EVERY_X_SECONDS * 1000);
        return () => clearInterval(checkExpiryIntervalId);
    }, []); // The empty dependency array ensures this useEffect runs only once on mount
}

const CheckExpiryDateInLoopInner = (props) => {
	useCheckTokenExpiry(() => {
        handleSignOut(props.dispatch);
        window.location.href = '/';
    })

	return null;
}

export const CheckExpiryDateInLoop = connect()(CheckExpiryDateInLoopInner);


import React, { Component } from "react";
import { Field, change, untouch } from "redux-form";
import { Emitter } from "react-emitter";
import { customInput } from "_fields/inputs";
import { required } from "_fields/validation";
import { verifyCode } from "_fields/normalizers";
import ContinueButton from "_shared/Continue/Button";
import { clearTemp } from "actions";
import "./Verify.scss";

class VerifyForm extends Component {
	constructor(props) {
		super();

		this.onChange = this.onChange.bind(this);
		this.resendCode = this.resendCode.bind(this);
		this.clearVerifyField = this.clearVerifyField.bind(this);
		this.updateFormValues = this.updateFormValues.bind(this);

		this.verifyCodeInputRef = React.createRef();
	}

	componentDidMount() {
		this.clearListener = this.props.addListener("clearVerifyCode", () => {
			this.clearVerifyField();
			this.focusVerifyField();
		});

		this.updateListener = this.props.addListener("updateFormValues", () => {
			this.updateFormValues();
		});

		if (this.verifyCodeInputRef) {
			this.clearVerifyField();
			this.focusVerifyField();
		}
	}

	componentWillUnmount() {
		this.clearListener.remove();
		this.updateListener.remove();
	}

	clearVerifyField() {
		const { form } = this.props;
		// clear the verifyCode input value
		this.props.dispatch(change(form, "verify_code", ""));
		//reset the field's error
		this.props.dispatch(untouch(form, "verify_code"));
	}

	focusVerifyField() {
		this.verifyCodeInputRef.current.focus();
	}
	/**
	 * I'm taking the approach of manually changing the form values instead of passing in as initialValues prop on purpose.
	 * The reasoning is that upon remount of components they will override with data returned from api.
	 * This approach allows conditionally setting the form values at this specific time and then discarding them.
	 * Any subsequent mounting of the components will use the redux form values as we'd expect.
	 */
	updateFormValues() {
		const { temp } = this.props;

		// update business values
		Object.entries(temp.business).forEach(([key, val]) => {
			this.updateField(key, val);
		});

		// update owner values / affiliate/vendor signin returns different shape object, no owners
		if (temp.owners) {
			Object.keys(temp.owners).forEach((idx) => {
				let owner = temp.owners[idx];

				Object.entries(owner).forEach(([key, val]) => {
					this.updateField(key, val, idx);
				});
			});
		}

		// update deal/loan values, but only if loan data was returned
		// if using /user/signin and there is not an active deal, this
		// will be false
		if (temp.loan) {
			Object.entries(temp.loan).forEach(([key, val]) => {
				this.updateField(key, val);
			});
		}

		// delete the temp data from redux store as it is now redundant
		this.props.dispatch(clearTemp());

		this.clearVerifyField();
	}

	updateField(field, value, index = null) {
		// update indexed owner form field
		if (index && value) {
			let realIdx = ++index;
			this.props.dispatch(
				change("application", `owner_${realIdx}_${field}`, value)
			);
			return;
		}

		// update non-indexed form field
		if (value) {
			this.props.dispatch(change("application", field, value));
		}
	}

	onChange(e) {
		if (e.target.value.length === 4) {
			// setTimeout allows the store to be updated before submitting
			window.setTimeout(() => this.props.handleSubmit(), 0);
		}
	}

	resendCode() {
		this.props.openAlert("Resending...", "info", true, false);
		this.props.verifyRequest({
			verify_method: "text",
			resend: true,
		});
		this.clearVerifyField();
		this.focusVerifyField();


		// throw new Error('This is a test error from Resend');
	}

	render() {
		const { handleSubmit, mobile, email, invalid, saving } = this.props;

		const start = mobile?.substr(0, 1);
		const end = mobile?.substr(-2, 2);
		const obfuscatedMobile = `${start}**-***-**${end}`;


		// console.error('This is a test error RP');

		return (
			<form onSubmit={handleSubmit}>
				<h3>We just {email ? 'emailed' : 'texted'}  you</h3>

				<p>
					Please enter the 4-digit verification code sent to {email ? email : obfuscatedMobile}
				</p>

				<Field
					name="verify_code"
					component={customInput}
					type="tel"
					placeholder="0000"
					normalize={verifyCode}
					validate={[required]}
					onChange={this.onChange}
					focusRef={this.verifyCodeInputRef}
					className="verify-code-input"
					autoFocus
				/>

				<p className="verify-message">Didn't receive the code?</p>
				<span className="faux-link" onClick={this.resendCode}>
					Send again
				</span>

				<ContinueButton btnText="Verify" invalid={invalid} saving={saving} />
			</form>
		);
	}
}

export default Emitter(VerifyForm);

import React, { Component } from "react";
import { connect } from "react-redux";
import { formValueSelector, getFormValues } from "redux-form";
import { withRouter } from "react-router-dom";
import { setData } from "redux/actions";
import { format } from "date-fns";
import numeral from "numeral";
import Upload from "./Upload";

class DocsContainer extends Component {
	constructor(props) {
		super();

		this.saveDocs = this.saveDocs.bind(this);
		this.addNewAppToData = this.addNewAppToData.bind(this);
	}

	async saveDocs() {
		this.addNewAppToData();

		const post = {
			loan_id: this.props.loan_id,
		};

		// try {
		// 	const response = await this.props.callApi("docs/save", post);

		// 	this.props.nextRoute();
		// } catch (error) {
		// 	console.log('the error', error);
		// 	this.props.nextRoute();
		// }

	
		const response = await this.props.callApi("docs/save", post);

		// TODO: make better
		// Always proceed, even if there is an error
		// if (!response.error) {
		this.props.nextRoute();
		// }


	}

	addNewAppToData() {
		let newApp = {
			business_id: this.props.allValues.business_id,
			business: this.props.allValues.business_name,
			loan_id: this.props.allValues.loan_id,
			loan_stage: "Submitted",
			loan_date: format(new Date(), "MM/dd/yyyy"),
			loan_amount: numeral(this.props.allValues.loan_amount).format("$0,0"),
			loan_terms: this.props.allValues.loan_terms,
			loan_type: this.props.allValues.loan_type,
		};

		let dataCopy = Object.assign({ deals: [] }, this.props.data);
		dataCopy.deals.unshift(newApp);

		this.props.setData(dataCopy);
	}

	render() {
		return <Upload onSubmit={this.saveDocs} {...this.props} />;
	}
}

const selector = formValueSelector("application");

const mapStateToProps = (state) => {
	return {
		allValues: getFormValues("application")(state) || {},
		loan_id: selector(state, "loan_id"),
		theme: state.theme,
		data: state.data,
		user: state.user,
	};
};

export default connect(mapStateToProps, { setData })(withRouter(DocsContainer));

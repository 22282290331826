import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import { datadogRum } from '@datadog/browser-rum';
// import * as Sentry from "@sentry/browser";
import UploadIcon from "_icons/UploadIcon";
import { FilePond } from "react-filepond";
import ContinueButton from "_shared/Continue/Button";
import "filepond/dist/filepond.min.css";
import "Docs.scss"

class DocsUpload extends Component {
	constructor(props) {
		super(props);

		this.state = {
			files: [],
			invalid: true,
		};

		this.handleError = this.handleError.bind(this);
		// this.sendErrorToSentry = this.sendErrorToSentry.bind(this);
	}

	handleError(error, file) {
		let msg = error.message || error.body;

		switch (error.code) {
			case 403:
				msg = "Invalid token account combination";
				break;
			case 413:
				msg = "File too large. Max file size is 8MB";
				break;
			case 415:
				msg = "Uploaded files must be .jpg, .jpeg, .png, or .pdf";
				break;
			case 422:
				msg = "Upload was missing required account number";
				break;
		}

		this.props.openAlert(msg, "info", false, false);

		datadogRum.addError(error, {
			component: 'upload',
			msg: msg,
		});
	}

	// sendErrorToSentry(error, metadata) {
	// 	Sentry.configureScope((scope) => {
	// 		scope.setUser({
	// 			id: this.props.contact_id,
	// 			email: this.props.email,
	// 			Account: this.props.business_id,
	// 			Mobile: this.props.mobile,
	// 		});
	// 	});
	// 	Sentry.withScope((scope) => {
	// 		if (metadata) {
	// 			Object.keys(metadata).forEach((key) => {
	// 				scope.setExtra(key, metadata[key]);
	// 			});
	// 		}
	// 		Sentry.captureException(error);vscode-file://vscode-app/Applications/Visual%20Studio%20Code.app/Contents/Resources/app/out/vs/code/electron-sandbox/workbench/workbench.html
	// 	});
	// }

	onUploadComplete(error, file) {
		if (error === null) {
			this.props.closeAlert();
			this.setState({ invalid: this.state.files.length === 0 });
		}
	}

	render() {
		const { saving, theme, user, handleSubmit } = this.props;

		let docType, message;
		if (user.type === "vendor") {
			docType = "quote";
			message = "Please upload your customer quote / invoice";
		} else if (user.type === "affiliate") {
			docType = "stmt";
			message =
				"Please upload your customer’s required underwriting information: Last 3 months business bank statements";
		}

		const apiUrl = API_URL || "";

		return (
			<div className="app-section slide">
				<div className="form-width">
					<UploadIcon fill={theme.primary} />
					<h3>Upload Docs</h3>

					<p>{message}</p>

					<FilePond
						ref={(ref) => (this.pond = ref)}
						files={this.state.files}
						name="docs"
						allowMultiple={true}
						maxFiles={6}
						server={{
							process: {
								url: apiUrl + "/api/portal/docs/upload",
								headers: { Authorization: "Bearer " + user.token },
							},
							revert: null,
							fetch: null,
							remove: null,
						}}
						onaddfile={(error, file) => {
							file.setMetadata("docType", docType);
							file.setMetadata("userType", "vendor"); // this is necessary to avoid 403 due to token/id mismatch
							file.setMetadata("account_id", this.props.business_id);
							file.setMetadata("contact_id", this.props.contact_id);
						}}
						onerror={(error, file) => {
							this.handleError(error, file);
						}}
						labelFileProcessingError={(error) => {
							return error.body;
						}}
						onupdatefiles={(fileItems) => {
							this.setState({
								files: fileItems.map((fileItem) => fileItem.file),
							});
						}}
						onprocessfile={(error, file) => this.onUploadComplete(error, file)}
					/>

					<p className="security-msg">
						We will upload and store your documents using industry standard
						security practices.
					</p>

					<form onSubmit={handleSubmit}>
						<ContinueButton
							invalid={this.state.invalid}
							saving={saving}
							showLock
						/>
					</form>
				</div>
			</div>
		);
	}
}

const selector = formValueSelector("application");

const mapStateToProps = (state) => {
	return {
		business_id: selector(state, "business_id"),
		contact_id: selector(state, "owner_1_id"),
		mobile: selector(state, "owner_1_mobile"),
		email: selector(state, "owner_1_email"),
		theme: state.theme,
		user: state.user,
	};
};

DocsUpload = reduxForm({
	form: "application",
	destroyOnUnmount: false,
	forceUnregisterOnUnmount: true,
})(DocsUpload);

export default connect(mapStateToProps)(DocsUpload);

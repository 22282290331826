import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { store, persistor } from "redux/store";

const User = lazy(() => import("./components/User"));
const Portal = lazy(() => import("./components/Portal"));

import ErrorBoundary from "./components/_shared/Error/Error";
import { CheckExpiryDateInLoop } from "./useJWTExpiryCheck";

datadogRum.init({
	applicationId: '85e2fca6-a8af-4a9d-8396-9bfebe28f723',
	clientToken: 'pub82e111ec7bf2fc46ff76bfdb55c87d84',
	site: 'datadoghq.com',
	service:'triton-portal',
	env: SENTRY_ENV,
	// Specify a version number to identify the deployed version of your application in Datadog 
	version: '1.0.1', 
	sessionSampleRate:100,
	sessionReplaySampleRate: 100,
	trackUserInteractions: true,
	trackResources: true,
	trackLongTasks: true,
	defaultPrivacyLevel:'allow'
});

datadogLogs.init({
  clientToken: 'pub82e111ec7bf2fc46ff76bfdb55c87d84',
  site: 'datadoghq.com',
	service:'triton-portal',
	env: SENTRY_ENV,
	version: '1.0.1', 
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
})


datadogRum.startSessionReplayRecording();




// Sentry.init({
// 	dsn: SENTRY_DSN,
// 	environment: SENTRY_ENV,
// 	integrations: [new BrowserTracing(), new Sentry.Replay()],
// 	tracesSampleRate: 0.5,
// 	replaysSessionSampleRate: 0.1,
// 	replaysOnErrorSampleRate: 1.0,
// });

// console.log('VENDOR_ID', VENDOR_ID);

const App = () => {
	const userRouteDefault = "/user/:action";
	const appRouteDefault = "/app/:step/:property?";
	const appRouteIndexed = "/app/:step/:idx/:property";
	return (
		<Router>
			<ErrorBoundary>
				<Switch>
					<Suspense fallback={<div>Loading...</div>}>
						<CheckExpiryDateInLoop />
						<Route exact path="/" component={User} />
						<Route exact path={userRouteDefault} component={User} />
						<Route exact path={appRouteDefault} component={Portal} />
						<Route exact path={appRouteIndexed} component={Portal} />
					</Suspense>
				</Switch>
			</ErrorBoundary>
		</Router>
	);
};

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<App />
		</PersistGate>
	</Provider>,
	document.getElementById("root")
);

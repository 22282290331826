import axios from "axios";
import { datadogRum } from '@datadog/browser-rum';

const call = async (route, data, user = {}) => {
	const headers =
		user && user.token ? { Authorization: "Bearer " + user.token } : {};

	// add site identifier to all post requests
	data.siteId = VENDOR_NAME;
	const apiUrl = API_URL || "";
	return await axios
		.post(`${apiUrl}/api/portal/${route}`, data, { headers: headers })
		.then(function (response) {
			let result = Object.assign({}, response.data);

			if (!response.data) {
				result.msg = "response.data was empty";
			}

			if (result.error) {

				const error = new Error(`Code 1. serviceApi.call result error. [${result.msg}], Endpoint: ${apiUrl}/api/portal/${route}`);

				datadogRum.addError(error, {
					api_url: apiUrl,
					route: route,
					data: data,
					user: user,
					message: result.msg
				});

			}

			return result;
		})
		.catch((error) => {

			datadogRum.addError(error, {
				api_url: apiUrl || null,
				route: route || null,
				data: data || null,
				user: user || null,
				headers: headers || null,
				status: error.response && error.response.status || null,
				statusText: error.response && error.response.statusText || null,
				errors: error.response && error.response.data && error.response.data.errors || null,
				full_response: error.response || null,
			});

			// Handle unauthorized
			if (error?.response && error.response?.status === 401) {
				
				return {
					error: true,
					level: "warning",
					feedback: false,
					msg: "Unauthorized",
				};;
			}

			return {
				error: true,
				level: "warning",
				feedback: true,
				msg: error.response
					? error.response.statusText
					: "Unknown - Possible Network Error",
			};
		});
};

export const apiService = {
	call,
};
